define("discourse/plugins/discourse-ai/discourse/components/ai-helper-options-list", ["exports", "@ember/helper", "discourse/components/d-button", "truth-helpers/helpers/eq", "discourse/plugins/discourse-ai/discourse/components/ai-helper-custom-prompt", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _helper, _dButton, _eq, _aiHelperCustomPrompt, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const AiHelperOptionsList = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <ul class="ai-helper-options">
      {{#each @options as |option|}}
        {{#if (eq option.name "custom_prompt")}}
          <AiHelperCustomPrompt
            @value={{@customPromptValue}}
            @promptArgs={{option}}
            @submit={{@performAction}}
          />
        {{else}}
          <li data-name={{option.translated_name}} data-value={{option.id}}>
            <DButton
              @icon={{option.icon}}
              @translatedLabel={{option.translated_name}}
              @action={{fn @performAction option}}
              data-name={{option.name}}
              data-value={{option.id}}
              class="ai-helper-options__button"
            />
          </li>
        {{/if}}
      {{/each}}
    </ul>
  
  */
  {
    "id": "P15pwiQY",
    "block": "[[[1,\"\\n  \"],[10,\"ul\"],[14,0,\"ai-helper-options\"],[12],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,1]],null]],null],null,[[[41,[28,[32,0],[[30,2,[\"name\"]],\"custom_prompt\"],null],[[[1,\"        \"],[8,[32,1],null,[[\"@value\",\"@promptArgs\",\"@submit\"],[[30,3],[30,2],[30,4]]],null],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,\"li\"],[15,\"data-name\",[30,2,[\"translated_name\"]]],[15,\"data-value\",[30,2,[\"id\"]]],[12],[1,\"\\n          \"],[8,[32,2],[[16,\"data-name\",[30,2,[\"name\"]]],[16,\"data-value\",[30,2,[\"id\"]]],[24,0,\"ai-helper-options__button\"]],[[\"@icon\",\"@translatedLabel\",\"@action\"],[[30,2,[\"icon\"]],[30,2,[\"translated_name\"]],[28,[32,3],[[30,4],[30,2]],null]]],null],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]]]],[2]],null],[1,\"  \"],[13],[1,\"\\n\"]],[\"@options\",\"option\",\"@customPromptValue\",\"@performAction\"],false,[\"each\",\"-track-array\",\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/components/ai-helper-options-list.js",
    "scope": () => [_eq.default, _aiHelperCustomPrompt.default, _dButton.default, _helper.fn],
    "isStrictMode": true
  }), (0, _templateOnly.default)());
  var _default = _exports.default = AiHelperOptionsList;
});